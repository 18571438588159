import React, { useEffect, useState } from "react";
import About from "../About";
import Service from "../Services/Service";
import Contact from "../Contact/Contact";

function Herosection() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const phoneNumber = "+61 413 606 104";

  const getCleanNumber = () => {
    let cleaned = phoneNumber.replace(/\D/g, '');
    
    if (!cleaned.startsWith('61')) {
      cleaned = '61' + cleaned;
    }
    
    return cleaned;
  };

  const messageContent = "I'm interested in the Independent Sales Agent (Freelance) position I saw on Dev Consulting Website. Could you provide more details about the role?";

  const getWhatsAppUrl = () => {
    const cleanNumber = getCleanNumber();
    const encodedMessage = encodeURIComponent(messageContent);
    
    return `https://api.whatsapp.com/send?phone=${cleanNumber}&text=${encodedMessage}`;
  };

  const handleWhatsAppClick = (e) => {
    e.preventDefault();
    
    try {
      // Get the WhatsApp URL
      const url = getWhatsAppUrl();
      
      // Open in new tab
      window.open(url, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Error opening WhatsApp:', error);
      // Fallback for older browsers
      window.location.href = getWhatsAppUrl();
    }
  };

  const ListContent = [
    { title: "Cloud Solutions" },
    { title: "HRMS & Payroll" },
    { title: "ERP Solutions" },
    { title: "Task & Project Management" },
    { title: "File & Document Management (OCR)" },
    { title: "Meeting & Workflow Automation" },
    { title: "AI Chatbots & Summarization" },
    { title: "Asset Management & Tracking" },
    { title: "Legal Tech Solutions" },
    { title: "E-Commerce Platforms" },
    { title: "VOC AI Solutions" },
    { title: "PoS Systems" },
    { title: "IoT Solutions" },
  ];

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  const WelcomeModal = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-4 md:p-6 w-[90%] lg:w-[45%] shadow-lg">
        <div className="flex flex-col">
          <div className="flex justify-between items-center mb-2 md:mb-4">
            <h2 className="text-center text-[16px] md:text-[16px] w-full md:w-full font-bold">
              Earn 15% Commission!
            </h2>

            <button
              onClick={() => setIsModalOpen(false)}
              className="text-gray-500 hover:text-gray-700 transition-colors"
            >
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
          <p className="text-center text-[14px] md:text-[16px] font-semibold">
            Join Us as an Independent Sales Agent
          </p>
          <div className="mb-2 ">
            <marquee className="text-red-700 text-[14px] md:text-[16px] text-center mb-1 md:mb-3">
              Meerana DevConsulting is seeking independent sales agents to sell
              our B2B tech solutions with a 15% commission per sale.
            </marquee>
            <h1 className="text-[14px] md:text-[16px]">
              Role : Independent Sales Agent (Freelance)
            </h1>
            <h1 className="text-[14px] md:text-[16px]">Our Solutions : </h1>
            <ul className="text-gray-600 list-disc pl-5 my-2 max-h-[150px] overflow-y-scroll">
              {ListContent.map((item, index) => (
                <li key={index} className="text-[14px] md:text-[16px]">
                  {item.title}
                </li>
              ))}
            </ul>
            <p className="text-[12px] md:text-[14px] my-2 ">
              For more information conatact through WhatsApp
            </p>

            <div className="w-full flex justify-center items-center">
              <a
                href={getWhatsAppUrl()}
                onClick={handleWhatsAppClick}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center text-[12px] md:text-[16px] gap-2 px-2 py-1 md:px-4 md:py-2 bg-[#22A75D] text-white rounded-lg hover:bg-[#227e4b] transition-colors md:mt-4"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z" />
                </svg>
                Chat on WhatsApp
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* Show welcome modal */}
      {isModalOpen && <WelcomeModal />}

      {/* desktop view*/}
      <div className="relative overflow-y-hidden">
        {/* desktop view background image*/}
        <img
          className="hidden lg:block absolute w-[65%] right-0 -z-10"
          src="images/bg1.png"
          alt=""
        />

        {/* mobile view background image */}
        <img
          className="lg:hidden block absolute w-full right-0 -z-10"
          src="images/mobhome.png"
          alt=""
        />

        <div
          id="home"
          className="lg:flex justify-between relative lg:px-14 md:px-10 px-5"
        >
          <div className="lg:flex lg:flex-col lg:items-start h-auto hidden text-[#191B21]">
            {/* desktop banner content */}
            <p className="lg:w-[300px] w-[180px] text-[15px] lg:text-[30px] md:pt-[150px] xl:pt-[250px] md:font-bold lg:font-semibold leading-8">
              Streamlined Cloud Journeys, Unmatched Expertise, Uncompromised
              Security
            </p>
            <p className="w-[300px] lg:w-[370px] xl:w-[450px] text-[12px] xl:text-[14px] mt-5">
              With over 25 years in IT Industry. Over 7 years in cloud. And over
              11 years in security printing industry.
            </p>
            <a
              href="#about"
              className="bg-[#22A75D] text-white rounded-3xl px-3 py-2 mt-5 text-[12px]"
            >
              LEARN MORE
            </a>
          </div>

          <div className="lg:w-[50%] flex lg:justify-start justify-center">
            {/* desktop & mobile view hero image */}
            <img
              className="z-40 mt-[80px] md:mt-[185px] lg:mt-[100px] h-[75%] w-[65%] md:h-[75%] md:w-[60%] lg:h-[85%] lg:w-[75%] "
              src="images/man.png"
              alt=""
            />
          </div>

          {/* mobile-view banner content */}
          <div className="lg:hidden block justify-center items-center mt-5">
            <p className="text-[#191B21] text-center text-[18px] md:text-[24px] px-10 md:px-40 ">
              Streamlined Cloud Journeys, Unmatched Expertise, Uncompromised
              Security
            </p>
            <p className="px-12 md:px-32 text-[18px] text-center text-gray-500 mt-3">
              With over 25 years in IT Industry. Over 7 years in cloud. And over
              11 years in security printing industry.
            </p>
            <div className="w-full flex justify-center items-center mt-3">
              <a
                href="#about"
                className="bg-[#22A75D] text-white rounded-3xl px-3 py-2 mt-5 text-[12px]"
              >
                LEARN MORE
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* about */}
      <About />

      {/* services */}
      <Service />

      {/* contact */}
      <Contact />
    </>
  );
}

export default Herosection;
