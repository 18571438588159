import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Herosection from "./Components/herosection/index";
import Navbar from "./Components/navbar";
import Footer from "./Components/footer";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const spinnerItems = Array.from({ length: 10 }, (_, index) => ({
    delay: (index + 1) * 0.1,
    rotation: 36 * (index + 1),
    translation: 150,
  }));

  return (
    <BrowserRouter>
      {isLoading ? (
        <div className="w-full h-auto min-h-[100vh] flex items-center justify-center">
          <div className="absolute w-[9px] h-[9px]">
            {spinnerItems.map((item, index) => (
              <div
                key={index}
                className="absolute w-1/2 h-[150%] bg-[#474bff] animate-[spinner-bounce_1s_infinite_ease]"
                style={{
                  animationDelay: `${item.delay}s`,
                  transform: `rotate(${item.rotation}deg) translate(0, ${item.translation}%)`,
                }}
              />
            ))}
          </div>
        </div>
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Herosection />} />
          </Routes>
          <Footer />
        </>
      )}
    </BrowserRouter>
  );
}

export default App;
